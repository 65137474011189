import "./Footer.css";
import BuildNow from "../BuildNow";
const Footer = () => {
  return (
    <footer className="footer">
      <div className="before-seperation-line-content">
        <div className="left-item">
          <img
            style={{
              width: "90px",
              height: "120px",
              background: "transparent",
            }}
            src="/Images/logo.svg"
            alt="logo"
          />
        </div>
        <div className="right-item">
          <img src="/Images/div-img.svg" alt=" " />
          <div className="bl">
            <BuildNow></BuildNow>
          </div>
        </div>
      </div>
      <div className="seperation-line"></div>
      <div className="footer-div">
        <div className="footer-link">
          <ul>
            <li>
              <a href="https://wa.me/19095896258">contact</a>
            </li>
            <li>
              <a href="https://tally.so/r/meQP1k">start</a>
            </li>
            <li>
              <a href="#">home</a>
            </li>
            <li>
              <a href="https://tally.so/r/nWEkJk">blog</a>
            </li>
          </ul>
        </div>
        <div className="social-sites">
          <ul>
            <li>
              <a href="https://www.twitter.com/al_ongsi_de" target='blank'>
                <img src="/Images/Twitter.svg" alt="Twitter" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/al_ongsi_de" target='blank'>
                <img src="/Images/Instagram.svg" alt="intagram" />
              </a>
            </li>
            <li>
                <a href="https://wa.me/19095896258">
                <img src="/Images/Chat.svg" alt="Chat" />
              </a>
            </li>
            <li>
              <a href="mailto:harshitmesaini@gmail.com">
                <img src="/Images/Email.svg" alt="Email" />
              </a>
            </li>
            <li>
              {" "}
              <a href="https://www.linkedin.com/company/al-ongsi-de/" target='blank'>
                <img src="/Images/Linkdin.svg" alt="Linkdin" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="contact">
        <div>
          {" "}
          <a href="https://wa.me/19095896258"> +1 (909)589-6258</a>
        </div>
        <div>
          {" "}
          <a href="mailto:harshitmesaini@gmail.com">school@al.ongsi.de</a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
