import "./SectionFourth.css";
import BuildNow from "../BuildNow";
const SectionFourth = () => {
  return (
    <div className="section-fourth">
      <div className="text-9">Ideate, Brainstorm and Build !</div>
      <div className="text-10">
        Don’t let that idea rent free in your mind, Bring it to life. Ideate it
        further, Brainstorm it, Iterate it and Iterate it again and again until
        it’s built.
      </div>
      <div>
        <BuildNow></BuildNow>
      </div>
    </div>
  );
};
export default SectionFourth;
