import "./HeroSection.css";
const HeroSection = () => {
  return (
    <div className="hero-section">
      <div className="school-builder">A School for Builders</div>
      <div className="gurudiv">
        <span className="gurukul">Gurukul </span>
        <span className="for">for</span>
        <br />
        <div className="builders">builders</div>
      </div>
      <div className="learning-heaven">
        A <span>School</span> where builders build together
      </div>
      <div className="wrap">
        <div className="overlap-blur">
          <div className="created-by">
            <img
              style={{ width: "17px", height: "25px" }}
              src="/Images/logo.svg"
              alt="logo"
            />
            <div>Built for Builders</div>
          </div>
          <div className="designed-by">by BoostMySuccess</div>
        </div>
        <div className="hero-image">
          <img src="/Images/img.svg" alt=""></img>
        </div>
      </div>
      <div className="transfer-idea">
        Transfer the idea from brain to real life{" "}
      </div>
      <div className="build-weekend">Build on weekends </div>
      <div className="musician">
        You can be a musician who wants to launch their album or you can be the
        founder who want to build a startup, Add or you can be a experimenter
        who builds shit for fun{" "}
      </div>
    </div>
  );
};

export default HeroSection;
