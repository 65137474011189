import "./SectionFirst.css";
import BuildNow from "../BuildNow";

const SectionFirst = () => {
  return (
    <div className="grid-container-1">
      <section>
        <div className="text-1">Become that Builder</div>
        <div className="text-2">Build your ideas that you always wanted to</div>
        <div className="text-3">
          Not everyone can go all in the side project. And the side project
          can’t be completed within a day or two, as good things take time so build
          with Gurukul in your nights and weekend and bring that abandoned idea
          to life
        </div>
      </section>
      <div className="div-right-image">
        <img src="/Images/Right-Image.svg" alt="" />
      </div>
      <div className="build-btn-1">
        <BuildNow></BuildNow>
      </div>
    </div>
  );
};
export default SectionFirst;
