import "./Navbar.css";
import BuildNow from "./BuildNow";
import { useRef } from "react";
const Navbar = () => {
  const menuBarRef = useRef(null);
  const menuBarHandler = () =>
    menuBarRef.current.style.display === "none"
      ? (menuBarRef.current.style.display = "block")
      : (menuBarRef.current.style.display = "none");

  return (
    <div className="navbar">
      <div className="logo">
        <img
          style={{ width: "100px", height: "120px" }}
          src="/Images/logo.svg"
          alt="logo"
        />
      </div>
      <nav className="nav-link">
        <ul>
          <li>
            <a href="https://wa.me/19095896258">Contact</a>
          </li>
          <li>
            <a href="https://tally.so/r/meQjDk">About</a>
          </li>
          <li>
            <a href="https://tally.so/r/nWEkJk">Blogs</a>
          </li>
        </ul>
      </nav>
      <img
        className="hamburger"
        src="/Images/menu-hamburger.svg"
        alt="  f"
        onClick={menuBarHandler}
      />
      <div className="nav-btn">
        <BuildNow></BuildNow>
      </div>
      <ul className="deviceview" ref={menuBarRef}>
        <li>
          <a href="https://wa.me/19095896258">contact</a>
        </li>
        <li>
          <a href="https://tally.so/r/meQjDk">about</a>
        </li>
        <li>
          <a href="https://tally.so/r/nWEkJk">Blogs</a>
        </li>
      </ul>
    </div>
  );
};
export default Navbar;
