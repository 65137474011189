import SectionFirst from "./SectionFirst";
import SectionSecond from "./SectionSecond";
import SectionThird from "./SectionThird";
import SectionFourth from "./SectionFourth";

const About = () => {
  return (
    <>
      <SectionFirst></SectionFirst>
      <SectionSecond></SectionSecond>
      <SectionThird></SectionThird>
      <SectionFourth></SectionFourth>
    </>
  );
};
export default About;
