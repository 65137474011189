import './App.css';
import Navbar from './Components/Navbar';
import HeroSection from './Components/Hero/HeroSection';
import About from './Components/About/About';
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Navbar></Navbar>
      <HeroSection></HeroSection>
      <About></About>
      <Footer></Footer>
    </div>
      
  );
}

export default App;
