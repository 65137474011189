import "./SectionThird.css";
const SectionThird = () => {
  return (
    <div className="section-third">
      <div className="text-7">it all begins with an idea. Crave it now!</div>
      <div className="text-8">
        A school for the crazy ones The misfits. The rebels. The round pegs in
        the square holes The ones who see things differently. The one’s who
        build shit for the better
      </div>
    </div>
  );
};
export default SectionThird;
