import "./SectionSecond.css";
import BuildNow from "../BuildNow";
const SectionSecond = () => {
  return (
    <div className="grid-container-2">
      <div className="left-image">
        <img src="/Images/Left-Image.svg" alt="" />
      </div>
      <section>
        <div className="text-4">A School for Misfits</div>
        <div className="text-5">
          If you don’t work on your ideas then who will ???........
        </div>
        <div className="text-6">
          Don’t have much time to work on the idea, Occupied with school, Job,
          business or whatever? No issues, make the idea Alive by putting up
          the stepping stones. Slowly and Steadily, over the weekends and
          holidays. Or, you might be the aggressive one who’s ready to go all
          in, and work on it as full time project. It’s for all who have the
          determination and consistency, to build, until done
        </div>
      </section>
      <div className="build-btn-2">
        <BuildNow></BuildNow>
      </div>
    </div>
  );
};
export default SectionSecond;
